import React from 'react';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import Benefits from '../components/benefits/benefits';
import Cta from '@codedrop/cta/esm/cta';
import Footer from '@codedrop/footer/esm/footer';
import setHTML from '@codedrop/utils/esm/utils';
import Image from '-!svg-react-loader!../images/graphic-integration.svg';
import data from '../data/skpr.json';
import integrations from '../data/integrations.json';

const { seo, header, footer, benefits, cta } = data;
const { site, pageTitle, subTitle, content } = integrations;

const IntegrationsPage = () => (
  <div>
    <SEO title={site.title} seo={seo} />
    <Header header={header} />
    <main>
      <Layout className="background--firework section--top-l">
        <Heading tag="h1" headingClass="heading--special">
          {pageTitle}
        </Heading>
        <Image />
        <Heading tag="h2" headingClass="heading--special heading--xxl">
          {setHTML(subTitle)}
        </Heading>
        <div className="grid">
          {content.map((item, index) => {
            return (
              <div
                className="grid__col grid--6-col"
                key={`integrations-${index}`}
              >
                <Heading tag="h3" headingClass="heading--m text--secondary">
                  {setHTML(item.title)}
                </Heading>
                <p>{setHTML(item.content)}</p>
              </div>
            );
          })}
        </div>
      </Layout>
      <Benefits benefits={benefits} />
      <Cta cta={cta} />
    </main>
    <Footer footer={footer} />
  </div>
);

export default IntegrationsPage;
